export default () => {
  return (
    <svg width="240" height="120" viewBox="0 0 240 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M59.6839 78.647H71.5737V66.8807H59.6839V78.647Z" fill="currentColor" />
      <path d="M55 82.2125H63.2869V74.3683H55V82.2125Z" fill="currentColor" />
      <path d="M74.0958 78.647H93.1916V59.3931H74.0958V78.647Z" fill="currentColor" />
      <path d="M58.2427 64.7414H77.3385V45.4876H58.2427V64.7414Z" fill="currentColor" />
      <path d="M79.8606 57.2538H98.9564V38H79.8606V57.2538Z" fill="currentColor" />
      <path d="M107.658 53.5242L109.566 53.3615C109.656 54.1072 109.864 54.7207 110.191 55.202C110.525 55.6766 111.039 56.063 111.734 56.3613C112.429 56.6528 113.21 56.7985 114.079 56.7985C114.85 56.7985 115.531 56.6867 116.122 56.463C116.713 56.2393 117.15 55.9342 117.435 55.5478C117.727 55.1546 117.873 54.7275 117.873 54.2665C117.873 53.7987 117.734 53.392 117.456 53.0462C117.178 52.6937 116.72 52.3988 116.08 52.1615C115.67 52.0056 114.763 51.7649 113.36 51.4395C111.956 51.1074 110.973 50.7955 110.41 50.504C109.68 50.1311 109.135 49.6701 108.773 49.121C108.419 48.5651 108.242 47.9448 108.242 47.2601C108.242 46.5076 108.461 45.806 108.899 45.1552C109.336 44.4976 109.976 43.9993 110.816 43.6603C111.657 43.3214 112.592 43.1519 113.62 43.1519C114.753 43.1519 115.75 43.3315 116.612 43.6908C117.481 44.0434 118.148 44.5654 118.613 45.2568C119.079 45.9483 119.329 46.7313 119.364 47.6059L117.425 47.7482C117.321 46.8059 116.966 46.0941 116.362 45.6128C115.764 45.1314 114.878 44.8908 113.704 44.8908C112.481 44.8908 111.588 45.1111 111.025 45.5517C110.469 45.9856 110.191 46.511 110.191 47.1279C110.191 47.6635 110.389 48.1041 110.785 48.4499C111.174 48.7956 112.189 49.1515 113.829 49.5176C115.476 49.8769 116.605 50.1922 117.216 50.4633C118.106 50.8633 118.763 51.3717 119.186 51.9887C119.61 52.5988 119.822 53.3038 119.822 54.1038C119.822 54.897 119.59 55.6461 119.124 56.3511C118.658 57.0494 117.988 57.5951 117.112 57.9883C116.244 58.3747 115.264 58.5679 114.173 58.5679C112.79 58.5679 111.629 58.3713 110.691 57.9781C109.76 57.5849 109.027 56.9951 108.492 56.2087C107.964 55.4156 107.686 54.5207 107.658 53.5242Z" fill="currentColor" />
      <path d="M127.049 56.6765L127.32 58.2934C126.792 58.4018 126.32 58.4561 125.903 58.4561C125.222 58.4561 124.694 58.351 124.318 58.1408C123.943 57.9307 123.679 57.6561 123.526 57.3172C123.373 56.9714 123.297 56.2494 123.297 55.1512V48.938H121.921V47.5143H123.297V44.8399L125.163 43.7417V47.5143H127.049V48.938H125.163V55.2529C125.163 55.7749 125.194 56.1104 125.257 56.2596C125.326 56.4087 125.434 56.5274 125.58 56.6155C125.733 56.7036 125.948 56.7477 126.226 56.7477C126.434 56.7477 126.709 56.724 127.049 56.6765Z" fill="currentColor" />
      <path d="M136.684 56.9816C135.989 57.5578 135.319 57.9646 134.673 58.2019C134.033 58.4391 133.345 58.5578 132.609 58.5578C131.393 58.5578 130.458 58.2697 129.805 57.6934C129.152 57.1104 128.825 56.3681 128.825 55.4664C128.825 54.9376 128.947 54.4563 129.19 54.0224C129.44 53.5818 129.763 53.2293 130.159 52.9649C130.562 52.7005 131.014 52.5005 131.514 52.3649C131.883 52.27 132.438 52.1785 133.182 52.0904C134.697 51.9141 135.812 51.7039 136.528 51.4599C136.535 51.209 136.538 51.0497 136.538 50.9819C136.538 50.2362 136.361 49.7108 136.007 49.4058C135.527 48.9922 134.815 48.7855 133.87 48.7855C132.987 48.7855 132.334 48.938 131.91 49.2431C131.493 49.5413 131.184 50.0735 130.983 50.8396L129.148 50.5955C129.315 49.8295 129.589 49.2126 129.972 48.7448C130.354 48.2702 130.906 47.9075 131.629 47.6567C132.352 47.3991 133.189 47.2703 134.141 47.2703C135.086 47.2703 135.854 47.3788 136.445 47.5957C137.035 47.8126 137.469 48.0872 137.747 48.4194C138.025 48.7448 138.22 49.1583 138.331 49.66C138.394 49.9718 138.425 50.5345 138.425 51.348V53.7886C138.425 55.4902 138.463 56.5681 138.54 57.0223C138.623 57.4697 138.783 57.9002 139.019 58.3137H137.06C136.865 57.9341 136.74 57.49 136.684 56.9816ZM136.528 52.8937C135.847 53.1649 134.825 53.3954 133.463 53.5852C132.692 53.6937 132.147 53.8157 131.827 53.9513C131.507 54.0868 131.261 54.2868 131.087 54.5512C130.913 54.8088 130.826 55.097 130.826 55.4156C130.826 55.9037 131.014 56.3104 131.389 56.6359C131.771 56.9613 132.327 57.124 133.057 57.124C133.78 57.124 134.422 56.9714 134.985 56.6664C135.548 56.3545 135.962 55.9308 136.226 55.3952C136.427 54.9817 136.528 54.3716 136.528 53.5648V52.8937Z" fill="currentColor" />
      <path d="M141.91 58.3137V47.5143H143.599V49.1515C144.029 48.3855 144.426 47.8804 144.787 47.6364C145.155 47.3923 145.558 47.2703 145.996 47.2703C146.628 47.2703 147.271 47.4669 147.924 47.8601L147.278 49.5583C146.819 49.2939 146.361 49.1617 145.902 49.1617C145.492 49.1617 145.124 49.2837 144.797 49.5278C144.471 49.7651 144.238 50.0972 144.099 50.5243C143.89 51.1751 143.786 51.887 143.786 52.6598V58.3137H141.91Z" fill="currentColor" />
      <path d="M153.744 56.6765L154.015 58.2934C153.487 58.4018 153.015 58.4561 152.598 58.4561C151.917 58.4561 151.389 58.351 151.013 58.1408C150.638 57.9307 150.374 57.6561 150.221 57.3172C150.068 56.9714 149.992 56.2494 149.992 55.1512V48.938H148.616V47.5143H149.992V44.8399L151.858 43.7417V47.5143H153.744V48.938H151.858V55.2529C151.858 55.7749 151.889 56.1104 151.951 56.2596C152.021 56.4087 152.129 56.5274 152.275 56.6155C152.427 56.7036 152.643 56.7477 152.921 56.7477C153.129 56.7477 153.404 56.724 153.744 56.6765Z" fill="currentColor" />
      <path d="M163.41 58.3137V56.7274C162.549 57.9476 161.378 58.5578 159.898 58.5578C159.244 58.5578 158.633 58.4357 158.063 58.1917C157.5 57.9476 157.08 57.6426 156.802 57.2765C156.531 56.9036 156.34 56.4494 156.229 55.9139C156.152 55.5545 156.114 54.9851 156.114 54.2055V47.5143H157.99V53.5038C157.99 54.4597 158.028 55.1037 158.105 55.4359C158.223 55.9172 158.473 56.2969 158.855 56.5748C159.237 56.846 159.71 56.9816 160.273 56.9816C160.836 56.9816 161.364 56.8426 161.857 56.5647C162.351 56.2799 162.698 55.8969 162.9 55.4156C163.108 54.9275 163.212 54.2224 163.212 53.3004V47.5143H165.089V58.3137H163.41Z" fill="currentColor" />
      <path d="M168.626 62.4525V47.5143H170.335V48.9177C170.738 48.3685 171.193 47.9584 171.701 47.6872C172.208 47.4093 172.823 47.2703 173.546 47.2703C174.491 47.2703 175.324 47.5076 176.047 47.9821C176.77 48.4567 177.315 49.1278 177.684 49.9956C178.052 50.8565 178.236 51.8022 178.236 52.8327C178.236 53.9377 178.031 54.9343 177.621 55.8223C177.218 56.7036 176.627 57.3816 175.849 57.8561C175.078 58.3239 174.265 58.5578 173.41 58.5578C172.785 58.5578 172.222 58.429 171.721 58.1714C171.228 57.9137 170.822 57.5883 170.502 57.1951V62.4525H168.626ZM170.325 52.975C170.325 54.3648 170.613 55.3919 171.19 56.0562C171.767 56.7206 172.465 57.0528 173.285 57.0528C174.119 57.0528 174.831 56.7104 175.422 56.0257C176.019 55.3342 176.318 54.2665 176.318 52.8225C176.318 51.4463 176.026 50.4159 175.443 49.7312C174.866 49.0465 174.174 48.7041 173.368 48.7041C172.569 48.7041 171.86 49.0702 171.242 49.8023C170.63 50.5277 170.325 51.5853 170.325 52.975Z" fill="currentColor" />
      <path d="M108.325 75.8075V60.8999H110.4L118.426 72.6043V60.8999H120.364V75.8075H118.29L110.264 64.0929V75.8075H108.325Z" fill="currentColor" />
      <path d="M131.111 72.3297L133.05 72.5636C132.744 73.6686 132.178 74.5262 131.351 75.1363C130.524 75.7465 129.467 76.0515 128.182 76.0515C126.563 76.0515 125.277 75.5668 124.325 74.5974C123.38 73.6212 122.908 72.2551 122.908 70.4993C122.908 68.6825 123.387 67.2724 124.346 66.2691C125.305 65.2657 126.549 64.7641 128.078 64.7641C129.558 64.7641 130.767 65.2556 131.705 66.2385C132.643 67.2215 133.112 68.6045 133.112 70.3875C133.112 70.4959 133.109 70.6586 133.102 70.8756H124.846C124.916 72.0619 125.26 72.9704 125.878 73.6008C126.497 74.2313 127.268 74.5465 128.192 74.5465C128.88 74.5465 129.467 74.3703 129.954 74.0178C130.44 73.6652 130.826 73.1026 131.111 72.3297ZM124.951 69.3706H131.132C131.048 68.4621 130.812 67.7808 130.423 67.3266C129.825 66.6216 129.051 66.2691 128.099 66.2691C127.237 66.2691 126.511 66.5504 125.92 67.1131C125.336 67.6758 125.013 68.4283 124.951 69.3706Z" fill="currentColor" />
      <path d="M139.512 74.1703L139.783 75.7871C139.255 75.8956 138.783 75.9498 138.366 75.9498C137.685 75.9498 137.156 75.8448 136.781 75.6346C136.406 75.4244 136.142 75.1499 135.989 74.8109C135.836 74.4652 135.76 73.7432 135.76 72.6449V66.4317H134.384V65.0081H135.76V62.3337L137.626 61.2354V65.0081H139.512V66.4317H137.626V72.7466C137.626 73.2686 137.657 73.6042 137.719 73.7534C137.789 73.9025 137.897 74.0211 138.042 74.1093C138.195 74.1974 138.411 74.2415 138.689 74.2415C138.897 74.2415 139.172 74.2177 139.512 74.1703Z" fill="currentColor" />
      <path d="M143.379 75.8075L139.992 65.0081H141.93L144.349 73.5601C144.376 73.4449 144.568 72.7026 144.922 71.3332L146.683 65.0081H148.612L150.269 71.2721L150.822 73.3364L151.457 71.2518L153.354 65.0081H155.179L151.718 75.8075H149.769L148.007 69.3401L147.58 67.4995L145.339 75.8075H143.379Z" fill="currentColor" />
      <path d="M156.106 70.4078C156.106 68.4079 156.676 66.9266 157.816 65.964C158.768 65.164 159.928 64.7641 161.297 64.7641C162.819 64.7641 164.063 65.2522 165.029 66.2284C165.995 67.1978 166.478 68.5401 166.478 70.2553C166.478 71.645 166.262 72.7399 165.831 73.5398C165.407 74.333 164.785 74.9499 163.966 75.3906C163.152 75.8312 162.263 76.0515 161.297 76.0515C159.748 76.0515 158.493 75.5668 157.534 74.5974C156.582 73.6279 156.106 72.2314 156.106 70.4078ZM158.035 70.4078C158.035 71.7908 158.344 72.828 158.962 73.5195C159.581 74.2042 160.359 74.5465 161.297 74.5465C162.228 74.5465 163.003 74.2008 163.622 73.5093C164.24 72.8178 164.549 71.7637 164.549 70.3468C164.549 69.0113 164.237 68.0012 163.611 67.3165C162.993 66.625 162.221 66.2792 161.297 66.2792C160.359 66.2792 159.581 66.6216 158.962 67.3063C158.344 67.991 158.035 69.0248 158.035 70.4078Z" fill="currentColor" />
      <path d="M168.666 75.8075V65.0081H170.355V66.6453C170.786 65.8792 171.182 65.3742 171.543 65.1301C171.912 64.8861 172.315 64.7641 172.752 64.7641C173.385 64.7641 174.028 64.9607 174.681 65.3539L174.035 67.0521C173.576 66.7877 173.117 66.6555 172.659 66.6555C172.249 66.6555 171.88 66.7775 171.554 67.0216C171.227 67.2588 170.994 67.591 170.855 68.0181C170.647 68.6689 170.543 69.3807 170.543 70.1536V75.8075H168.666Z" fill="currentColor" />
      <path d="M175.827 75.8075V60.8999H177.704V69.4011L182.144 65.0081H184.573L180.341 69.0147L185 75.8075H182.686L179.027 70.2858L177.704 71.5264V75.8075H175.827Z" fill="currentColor" />
    </svg>
  )
}
