export const FIRST_PARAGRAPH = {
  title: "",
  desc: "This Privacy policy is intended to meet our duties of transparency under relevant law, including the General Data Protection Regulation (“GDPR”) and applicable U.S. state privacy laws, such as the California Consumer Protection Act (as amended by the California Privacy Rights Act of 2020 and the California Consumer Privacy Act Regulations issued thereto (“CCPA”)), the Virginia Consumer Data Protection Act, the Colorado Privacy Act, the Connecticut Data Privacy Act, and the Utah Consumer Privacy Act. Privacy policy The purpose of creating our site is not to collect, store and process any personal information directly from the site visitors. Based on this, we comply with all possible Privacy policy requirements for sites of this kind. Also, we do not knowingly provide the services, and we do not collect personally identifiable information from anyone under the age of 18. The site may contain links to other sites, and we are not responsible for the privacy policies or the content of these sites. We encourage you to read the privacy policies of the linked sites. Their Privacy policy and Activities may differ from our Privacy policy and Activities. Changes to our privacy policy We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Privacy policy at any time. Any changes or updates will be effective immediately upon posting to this page. You should review this Privacy policy regularly for changes. You can determine if changes have been made by checking the Effective Date below. Your continued use of our Site following the posting of any changes to this Privacy policy means you consent to such changes. Contacting us If you have any questions or suggestions regarding the Privacy policy, please contact us: am@network.vc.",
};

export const COOKIE_PARAGRAPH = {
  title: "Privacy policy",
  desc: "The purpose of creating our site is not to collect, store and process any personal information directly from the site visitors. Based on this, we comply with all possible Privacy policy requirements for sites of this kind. Also, we do not knowingly provide the services, and we do not collect personally identifiable information from anyone under the age of 18. The site may contain links to other sites, and we are not responsible for the privacy policies or the content of these sites. We encourage you to read the privacy policies of the linked sites. Their Privacy policy and Activities may differ from our Privacy policy and Activities.",
};

export const CHANGES_PARAGRAPH = {
  title: "Changes to our privacy policy",
  desc: "We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Privacy policy at any time. Any changes or updates will be effective immediately upon posting to this page. You should review this Privacy policy regularly for changes. You can determine if changes have been made by checking the Effective Date below. Your continued use of our Site following the posting of any changes to this Privacy policy means you consent to such changes.",
};

export const CONTACTING_US_PARAGRAPH = {
  title: "Contacting us",
  desc: "If you have any questions or suggestions regarding the Privacy policy, please contact us: am@network.vc.",
};
