import React, { useContext, useEffect } from "react";
import Attention from "../layouts/svgs/Attention";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  ABOUTUS_ROUTE,
  FAQ_ROUTE,
  MAINPAGE_ROUTE,
  MEMBERSHIP_ROUTE,
} from "../utils/consts";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import LogoWhite from "../layouts/svgs/LogoWhite";
import Location from "../layouts/svgs/Location";
import Speaky__head_img_first from "../layouts/imgs/firstFooter.jpeg";
import Speaky__head_img_second from "../layouts/imgs/secondFooter.jpeg";
import Speaky__head_img_third from "../layouts/imgs/thirdFooter.jpeg";

import StartupNetwork from "../layouts/svgs/footerLogos/StartupNetwork";
import UnicornEvents from "../layouts/svgs/footerLogos/UnicornEvents";
import VcHouse from "../layouts/svgs/footerLogos/VcHouse";
import NetworkVc from "../layouts/svgs/footerLogos/NetworkVc";
import StartupInc from "../layouts/svgs/footerLogos/StartupInc";

const Footer = observer(() => {
  const { list } = useContext(Context);
  const navigate = useNavigate();

  const initGsap = () => {
    gsap.registerPlugin(ScrollTrigger);
    const fboxes = gsap.utils.toArray(".fAnimeBlock");

    fboxes.forEach((box, i) => {
      let number = box.getAttribute("data-n");
      gsap.fromTo(
        box,
        {
          opacity: 1,
          duration: 0.4,
        },
        {
          opacity: 1,
          // x: (i, target) => target.dataset.afterX,
          // y: (i, target) => target.dataset.afterY,

          duration: 0.4,
          ease: "none",
          scrollTrigger: {
            trigger: box,
            toggleClass: `fAnimeBlock_on${number}`,
            toggleActions: "play none none restart",
            start: "top 90%",
            endTrigger: "footer__rights",
            end: () => `+=${box.offsetHeight + 1400}`,
            // scrub: true,
            //  markers: true,
            invalidateOnRefresh: true,
            // markers: {
            //   startColor : "yellow",
            //   endColor : 'brown',
            //   fontSize : '5rem'
            // },
          },
        }
      );
    });
  };

  useEffect(() => {
    if (list.questionsIsOpened) {
      ScrollTrigger.refresh();
    }
  }, [list.questionsIsOpened]);

  useEffect(() => {
    setTimeout(() => {
      initGsap();
    }, 300);
  }, []);

  return (
    <section className=" section footer">
      <div className="footer__content">
        <div className="footer__content__left">
          <div className="footer__content__left__title">
            Start your membership now
            <div className="footer__attention">
              <Attention /> Don't miss deals
            </div>
          </div>

          <div
            onClick={() => window.open("https://startup.inc/en/ref365836765")}
            className="btn getStrd__btn"
          >
            Become a member
          </div>
        </div>
        <div className="footer__right__container">
          <div className="fAnimeBlock footer__right" data-n="0">
            <div className="footer__right__head">
              <div className="footer__right__head__title">Last deal</div>
              {/* <div className="footer__right__live">
                NEW
              </div> */}
            </div>
            <div className="footer__right__text">
              Artificial intelligence. Series A. $5.5m raised.
            </div>
          </div>
          <div className="fAnimeBlock footer__right__opaciti1" data-n="1"></div>
          <div className="fAnimeBlock footer__right__opaciti2" data-n="2"></div>
        </div>
      </div>

      <div className="footer__black">
        <div className="sectionWrap_footer__black">
          <div className="footer__black__flex">
            <div
              className="footer__black__flex__logo"
              onClick={() => navigate(MAINPAGE_ROUTE)}
            >
              <div style={{ marginTop: -8 }}>
                <LogoWhite />
              </div>
              {/* <div className="footer__black__social">
                <a href="/">
                  <Linkdn />
                </a>
                <a href="/">
                  <FB />
                </a>
                <a href="/">
                  <Twit />
                </a>
              </div> */}
            </div>

            <div className="footer__black__mob">
              <div className="footer__black__mob-nav">
                <div className="footer__black__flex__col footer__black__flex__col-left">
                  <div
                    className="footer__black__flex__col__li"
                    onClick={() => navigate(ABOUTUS_ROUTE)}
                  >
                    About us
                  </div>
                  <div
                    className="footer__black__flex__col__li"
                    onClick={() => navigate(MEMBERSHIP_ROUTE)}
                  >
                    Membership
                  </div>
                  {/* <div className="footer__black__flex__col__li" onClick={() => navigate(MAINPAGE_ROUTE)}>
                  Events
                </div> */}
                  <div
                    className="footer__black__flex__col__li"
                    onClick={() => navigate(FAQ_ROUTE)}
                  >
                    FAQ
                  </div>
                </div>

                <div className="footer__black__flex__col">
                  <div className="footer__black__flex__col__li greyLinks">
                    <Link
                      className="footer__black__flex__col__li greyLinks"
                      to="/privacy-policy"
                    >
                      Privacy policy
                    </Link>
                  </div>
                  <div className="footer__black__flex__col__li greyLinks">
                    <Link
                      className="footer__black__flex__col__li greyLinks"
                      to="/cookie-policy"
                    >
                      Cookie policy
                    </Link>
                  </div>
                  <div className="footer__black__flex__col__li greyLinks">
                    <Link
                      className="footer__black__flex__col__li greyLinks"
                      to="/legal"
                    >
                      Legal
                    </Link>
                  </div>
                  <div className="footer__black__flex__col__li greyLinks">
                    <Link
                      className="footer__black__flex__col__li greyLinks"
                      to="/risk-warnings"
                    >
                      Risk warnings
                    </Link>
                  </div>
                </div>
              </div>

              <div className="footer__address">
                <div className="footer__address-icon">
                  <Location />
                </div>
                <div className="footer__address-text">
                  111 Pier Avenue, Suite 100, Hermosa Beach, CA 90254
                </div>
              </div>
            </div>
          </div>

          <div className="speaky">
            <div className="speaky-wrapper">
              <div className="speaky__head">
                {/* <div className="speaky__head__title">Contact us</div> */}
                {/* <Email /> */}
                <img
                  src={Speaky__head_img_first}
                  className="speaky__head_img_first"
                />
                <img
                  src={Speaky__head_img_second}
                  className="speaky__head_img_second"
                />
                <img
                  src={Speaky__head_img_third}
                  className="speaky__head_img_third"
                />
              </div>
              <div className="speaky__text">
                Got questions? <br />
                Book a call
              </div>
              <div
                onClick={() => window.open("https://calendly.com/svsy/")}
                className="btn speakyBtn"
              >
                Book a call
              </div>
            </div>
          </div>

          {/* <div className="speaky">
            <div className="speaky-wrapper">
              <div className="speaky__head">
                <div className="speaky__head__title">Our ecosystem</div>
                <Speaky_Logo/>
              </div>
              <div onClick={() => window.open('https://network.vc/')} className="speaky__text">Network.VC</div>
              <div onClick={() => window.open('https://sv.club/')} className="speaky__text">SV.Club</div>
              <div onClick={() => window.open('https://startup.inc/en')} className="speaky__text">Startup.Inc</div>
              <div onClick={() => window.open('https://unicorn.events/')} className="speaky__text">Unicorn.Events</div>
              <div onClick={() => window.open('https://vc.house/')} className="speaky__text">VC.House</div>
              <div onClick={() => window.open('https://startup.network/')} className="speaky__text">Startup.Network</div>
            </div>
          </div> */}

          <div className="footer__rights">
            © sv.club, {new Date().getFullYear()}
          </div>
        </div>
        <div className="sectionWrap_footer__black sectionUnderFooter">
          <div className="underFooterText">Our ecosystem</div>
          <div className="underFooterLogos">
            <div
              onClick={() => window.open("https://network.vc/")}
              className="underFooterLogo"
            >
              <NetworkVc />
            </div>
            <div
              onClick={() => window.open("https://unicorn.events/")}
              className="underFooterLogo"
            >
              <UnicornEvents />
            </div>
            <div
              onClick={() => window.open("https://vc.house/")}
              className="underFooterLogo"
            >
              <VcHouse />
            </div>
            <div
              onClick={() => window.open("https://startup.inc/en")}
              className="underFooterLogo"
            >
              <StartupInc />
            </div>
            <div
              onClick={() => window.open("https://startup.network/")}
              className="underFooterLogo"
            >
              <StartupNetwork />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

// Footer.propTypes = {
//   questionsIsOpened : pt.bool,
// }

export default Footer;
