export const FIRST_PARAGRAPH = {
  title: "",
  desc: "This Cookies policy is intended to meet our duties of transparency under relevant law, including the General Data Protection Regulation (“GDPR”) and applicable U.S. state privacy laws, such as the California Consumer Protection Act (as amended by the California Privacy Rights Act of 2020 and the California Consumer Privacy Act Regulations issued thereto (collectively ”CCPA”)), the Virginia Consumer Data Protection Act, the Colorado Privacy Act, the Connecticut Data Privacy Act, and the Utah Consumer Privacy Act.",
};

export const COOKIE_PARAGRAPH = {
  title: "Cookies",
  desc: "What are cookies? When you visit our site, we may send one or more “cookies” – small data files – to your computer to uniquely identify your browser and let Silicon Valley Club log in faster and enhance your navigation through our site. A cookie may convey anonymous information about how you browse our site to us so we can provide you with a more personalized experience, but does not collect personal information about you. A persistent cookie remains on your computer after you close your browser so that it can be used by your browser on subsequent visits to our site. Persistent cookies can be removed by following your web browser’s directions. A session cookie is temporary and disappears after you close your browser.",
};

export const CHANGES_PARAGRAPH = {
  title: "Changes to our cookies policy",
  desc: "We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this Cookies policy at any time. Any changes or updates will be effective immediately upon posting to this page. You should review this Cookies policy regularly for changes. You can determine if changes have been made by checking the Effective Date below. Your continued use of our Site following the posting of any changes to this Cookies policy means you consent to such changes.",
};

export const CONTACTING_US_PARAGRAPH = {
  title: "Contacting us",
  desc: "If you have any questions or suggestions regarding the Cookies policy, please contact us: am@network.vc.",
};
