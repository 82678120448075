import {
  ABOUTUS_ROUTE,
  MAINPAGE_ROUTE,
  MEMBERSHIP_ROUTE,
  FAQ_ROUTE,
  ERROR_PAGE,
  PRIVACY_POLICY_ROUTE,
  COOKIE_SETTINGS_ROUTE,
  LEGAL_PAGE_ROUTE,
  RISK_WARNINGS_ROUTE,
} from "./utils/consts";
import { Main } from "./pages/Main";
import { AboutUs } from "./pages/AboutUs";
import { Membership } from "./pages/Membership";
import { Faq } from "./pages/Faq";
import PageError from "./pages/PageError";
import { CookieSettings } from "./pages/CookieSettings";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { LegalPage } from "./pages/Legal";
import { RiskWarnings } from "./pages/RiskWarnings";

export const PUBLIC_ROUTES = [
  {
    path: MAINPAGE_ROUTE,
    Component: Main,
  },
  {
    path: ABOUTUS_ROUTE,
    Component: AboutUs,
  },
  {
    path: MEMBERSHIP_ROUTE,
    Component: Membership,
  },
  {
    path: FAQ_ROUTE,
    Component: Faq,
  },
  {
    path: ERROR_PAGE,
    Component: PageError,
  },
  {
    path: PRIVACY_POLICY_ROUTE,
    Component: PrivacyPolicy,
  },
  {
    path: COOKIE_SETTINGS_ROUTE,
    Component: CookieSettings,
  },
  {
    path: LEGAL_PAGE_ROUTE,
    Component: LegalPage,
  },
  {
    path: RISK_WARNINGS_ROUTE,
    Component: RiskWarnings,
  },
];
