export const FIRST_PARAGRAPH = {
  title: "",
  desc: "Investing in early-stage businesses can be very rewarding, but it involves a number of risks and challenges. If you choose to invest in businesses presented on the Startup.inc platform(the investment electronic platform used by Silicon Valley Club members to invest) you need to be aware of important considerations below.",
};

export const LOSS_OF_CAPITAL_PARAGRAPH = {
  title: "Loss of capital",
  desc: "Most early-stage businesses fail, and if you invest in a business displayed on the Startup.inc platform(the investment electronic platform used by Silicon Valley Club members to invest) it is significantly more likely that you will lose all of your invested capital than you will see any return of capital or a profit. You should only invest what you can afford to lose. Do not invest so much that it would impact your lifestyle or retirement plans. Every investment made as a member of the Silicon Valley Club, is much riskier than a public company listed on the stock market. It is entirely possible that you will lose every dollar you invest in any start-up.",
};

export const ILLIQUIDITY_PARAGRAPH = {
  title: "Illiquidity",
  desc: "Almost all investments you make in businesses displayed on the Startup.inc platform (the investment electronic platform used by Silicon Valley Club members to invest) will be highly illiquid. It is very unlikely that there will be a liquid secondary market for the shares of the business. This means you should assume that you will be unlikely to be able to sell your shares until and unless the business floats on a stock exchange or is bought by another company; and, even if the business is bought by another company or floats, your investment may continue to be illiquid. Even for a successful business, a flotation or purchase is unlikely to occur for a number of years from the time you make your investment. For businesses for which secondary market opportunities are available , it can be difficult to find a buyer or seller, and investors who invest as members of the Silicon Valley Club should not assume that an early exit will be available just because a secondary market exists.",
};

export const RARITY_OF_DIVIDENDS_PARAGRAPH = {
  title: "Rarity of dividends",
  desc: "Businesses of the type displayed on the Startup.inc platform (the investment electronic platform used by Silicon Valley Club members to invest) rarely pay dividends. This means that if you invest in a business through the platform, even if it is successful you are unlikely to see any return of capital or profit until you are able to sell your shares. Even for a successful business, this is unlikely to occur for a number of years from the time you make your investment.",
};

export const DILUTION_PARAGRAPH = {
  title: "Dilution",
  desc: "Any investment you make in a business displayed on the Startup.inc platform (the investment electronic platform used by Silicon Valley Club members to invest) is likely to be subject to dilution. This means that if the business raises additional capital at a later date, it will issue new shares to the new investors, and the percentage of the business that you own will decline.",
};

export const DIVERSIFICATION_PARAGRAPH = {
  title: "Diversification",
  desc: "You are more likely to avoid loss by diversifying your investments, focusing on areas in which you have experience by investing in startups whose products you regularly use. We recommend making several small investments each year rather than one large one. For instance, if you decide you can safely invest $10,000 per year in startups, it will be less risky to make ten $1,000 investments instead of a single $10,000 one.",
};

export const VIEWS_PARAGRAPH = {
  title: "Views",
  desc: "Views expressed in social media, startup owner presentations, and meetings with startup owners organized by Silicon Valley Club, as well as the views of individual non-authorized employees of Silicon Valley Club, invited experts, may not reflect the views of Silicon Valley Club or its respective affiliates.",
};

export const INVESTMENT_CONDITIONS_PARAGRAPH = {
  title: "Investment conditions and opportunities",
  desc: "The conditions for investing in the proposed startups on the Startup.inc platform, (the investment electronic platform used by Silicon Valley Club members to invest), including the subject of investments, their value, mandatory fees and other characteristics, are set out in documents that you can always find at The Startup.inc platform is invested by members of the Silicon Valley Club prior to signing and may differ materially from the terms and conditions presented in startup presentations or the terms and opportunities for investing in other startups.",
};
