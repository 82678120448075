import React from "react";

import NavBar from "../../components/NavBar";
import Container from "../../layouts/Container";
import { CookieSection } from "../../components/CookieSection";
import { FIRST_PARAGRAPH as PARAGRAPH } from "./consts";
import Footer from "../../components/Footer";
import styles from "./index.module.css";
import { LegalSvg } from "./source/LegalSvg";

export const LegalPage = () => {
  return (
    <div className="faq">
      <NavBar coloredLogo />
      <Container>
        <div className="section headSection">
          <div className="top__title">
            <h1 className="top__h1">Legal</h1>
          </div>
        </div>
        <div className={styles.cookieSections}>
          <CookieSection {...PARAGRAPH} />
          <CookieSection>
            <div className={styles.legalImage}>
              <LegalSvg width={"100%"} maxWidth="630px"/>
            </div>
          </CookieSection>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
