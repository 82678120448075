import React from "react";
import NavBar from "../../components/NavBar";
import Container from "../../layouts/Container";
import { CookieSection } from "../../components/CookieSection";
import { CHANGES_PARAGRAPH, CONTACTING_US_PARAGRAPH, COOKIE_PARAGRAPH, FIRST_PARAGRAPH } from "./consts";
import Footer from "../../components/Footer";
import styles from './index.module.css';

export const PrivacyPolicy = () => {
  return (
    <div className="faq">
      <NavBar coloredLogo />
      <Container>
        <div className="section headSection">
          <div className="top__title">
            <h1 className="top__h1">Privacy policy</h1>
          </div>
        </div>
        <div className={styles.cookieSections}>
          <CookieSection {...FIRST_PARAGRAPH} />
          <CookieSection {...COOKIE_PARAGRAPH} />
          <CookieSection {...CHANGES_PARAGRAPH} />
          <CookieSection {...CONTACTING_US_PARAGRAPH} />
        </div>
      </Container>
      <Footer />
    </div>
  );
};
