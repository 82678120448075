import React from "react";
import NavBar from "../../components/NavBar";
import Container from "../../layouts/Container";
import { CookieSection } from "../../components/CookieSection";
import {
  FIRST_PARAGRAPH,
  LOSS_OF_CAPITAL_PARAGRAPH,
  ILLIQUIDITY_PARAGRAPH,
  RARITY_OF_DIVIDENDS_PARAGRAPH,
  DILUTION_PARAGRAPH,
  DIVERSIFICATION_PARAGRAPH,
  VIEWS_PARAGRAPH,
  INVESTMENT_CONDITIONS_PARAGRAPH,
} from "./consts";
import Footer from "../../components/Footer";
import styles from "./index.module.css";

export const RiskWarnings = () => {
  return (
    <div className="faq">
      <NavBar coloredLogo />
      <Container>
        <div className="section headSection">
          <div className="top__title">
            <h1 className="top__h1">Risk warnings</h1>
          </div>
        </div>
        <div className={styles.cookieSections}>
          <CookieSection {...FIRST_PARAGRAPH} />
          <CookieSection {...LOSS_OF_CAPITAL_PARAGRAPH} />
          <CookieSection {...ILLIQUIDITY_PARAGRAPH} />
          <CookieSection {...RARITY_OF_DIVIDENDS_PARAGRAPH} />
          <CookieSection {...DILUTION_PARAGRAPH} />
          <CookieSection {...DIVERSIFICATION_PARAGRAPH} />
          <CookieSection {...VIEWS_PARAGRAPH} />
          <CookieSection {...INVESTMENT_CONDITIONS_PARAGRAPH} />
        </div>
      </Container>
      <Footer />
    </div>
  );
};
