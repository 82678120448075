export default () => {
  return (
    <svg width="240" height="120" viewBox="0 0 240 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M173.172 51.1757H176.381L181.143 66.3633L185.906 51.1757H189.115L183.099 69.702H179.188L173.172 51.1757Z" fill="currentColor"/>
    <path d="M197.31 69.8526C195.739 69.8526 194.485 69.61 193.55 69.1246C192.614 68.6225 191.895 67.6854 191.394 66.313C190.909 64.9407 190.667 62.9576 190.667 60.3636C190.667 57.9034 190.917 55.9956 191.419 54.64C191.92 53.2677 192.655 52.3221 193.625 51.8033C194.594 51.2678 195.847 51 197.385 51C198.387 51 199.39 51.0837 200.393 51.251C201.412 51.4017 202.281 51.5857 203 51.8033V54.1881C202.499 54.0877 201.78 53.9957 200.844 53.912C199.925 53.8283 198.981 53.7865 198.012 53.7865C196.909 53.7865 196.056 53.9622 195.455 54.3136C194.87 54.6484 194.444 55.2843 194.176 56.2215C193.909 57.1587 193.775 58.5561 193.775 60.4138C193.775 62.2714 193.9 63.6688 194.151 64.606C194.402 65.5432 194.82 66.1875 195.404 66.539C195.989 66.8904 196.85 67.0661 197.986 67.0661C199.892 67.0661 201.563 66.9741 203 66.79V69.1748C201.479 69.6267 199.582 69.8526 197.31 69.8526Z" fill="currentColor"/>
    <path d="M37 51.2765H38.2284L48.4058 67.1669V51.2765H49.8595V69.7023H48.6562L38.454 53.8119V69.7023H37V51.2765Z" fill="currentColor"/>
    <path d="M59.2794 69.8027C58.1934 69.8027 57.2992 69.5768 56.5974 69.125C55.8955 68.6731 55.5444 67.7526 55.5444 66.3636V54.8161C55.5444 53.5776 55.8704 52.6823 56.5221 52.13C57.1737 51.561 57.976 51.2765 58.9286 51.2765C59.8141 51.2765 61.7778 51.3183 64.8195 51.402L66.5991 51.4773V52.607H59.3045C58.5692 52.607 58.0011 52.8078 57.5998 53.2094C57.1988 53.5944 56.9983 54.1885 56.9983 54.9918V59.8869L65.5464 60.0124V61.0919L56.9983 61.2174V66.0875C56.9983 67.0247 57.2239 67.6522 57.6751 67.9702C58.1263 68.2882 58.7281 68.4472 59.4799 68.4472H66.5991V69.5768C64.3934 69.6772 61.9535 69.7525 59.2794 69.8027Z" fill="currentColor"/>
    <path d="M76.4502 52.5819H70.5593V51.2765H83.795V52.5819H77.9042V69.7023H76.4502V52.5819Z" fill="currentColor"/>
    <path d="M87.1685 51.2765H88.6977L92.9592 67.8196L97.8473 51.2765H99.4014L104.265 67.8196L108.551 51.2765H110.055L105.217 69.7023H103.563L98.6245 53.2094L93.6611 69.7023H92.0067L87.1685 51.2765Z" fill="currentColor"/>
    <path d="M121.347 69.8528C119.642 69.8528 118.305 69.6353 117.336 69.2002C116.367 68.7483 115.631 67.8613 115.13 66.5392C114.629 65.2004 114.378 63.2172 114.378 60.5897C114.378 57.9622 114.629 55.9707 115.13 54.6151C115.648 53.2428 116.4 52.3223 117.386 51.8537C118.372 51.3684 119.692 51.1257 121.347 51.1257C123.051 51.1257 124.388 51.3684 125.358 51.8537C126.344 52.3223 127.079 53.2344 127.564 54.59C128.065 55.9456 128.315 57.9455 128.315 60.5897C128.315 63.2172 128.065 65.2004 127.564 66.5392C127.062 67.8613 126.327 68.7483 125.358 69.2002C124.388 69.6353 123.051 69.8528 121.347 69.8528ZM121.347 68.5475C122.767 68.5475 123.853 68.355 124.606 67.9701C125.374 67.5852 125.943 66.8321 126.31 65.7108C126.678 64.5728 126.862 62.8658 126.862 60.5897C126.862 58.2133 126.678 56.4477 126.31 55.2929C125.943 54.1381 125.374 53.3767 124.606 53.0085C123.853 52.6236 122.767 52.4311 121.347 52.4311C119.993 52.4311 118.932 52.6236 118.163 53.0085C117.411 53.3767 116.835 54.1465 116.434 55.318C116.032 56.4895 115.832 58.2467 115.832 60.5897C115.832 62.8825 115.999 64.5895 116.333 65.7108C116.684 66.8321 117.236 67.5852 117.988 67.9701C118.756 68.355 119.876 68.5475 121.347 68.5475Z" fill="currentColor"/>
    <path d="M133.922 51.2765H141.066C142.436 51.2765 143.548 51.6447 144.4 52.381C145.269 53.1007 145.704 54.4897 145.704 56.5482C145.704 58.4058 145.344 59.7028 144.626 60.4392C143.924 61.1756 143.055 61.6191 142.019 61.7697L145.879 69.7023H144.425L140.64 61.9203C139.036 61.9203 137.281 61.8868 135.376 61.8199V69.7023H133.922V51.2765ZM140.891 60.7153C141.943 60.7153 142.762 60.4308 143.347 59.8618C143.949 59.2761 144.25 58.1715 144.25 56.5482C144.25 55.0085 143.949 53.9625 143.347 53.4103C142.745 52.858 141.927 52.5819 140.891 52.5819H135.376V60.7153H140.891Z" fill="currentColor"/>
    <path d="M151.007 51.2765H152.461V59.8367H155.97L161.385 51.2765H162.939L157.123 60.4894L162.989 69.7023H161.41L155.945 61.1421H152.461V69.7023H151.007V51.2765Z" fill="currentColor"/>
    <path d="M167.822 69.7021C167.721 69.7021 167.638 69.677 167.571 69.6268C167.504 69.5599 167.471 69.4678 167.471 69.3506V67.5181C167.471 67.2838 167.588 67.1666 167.822 67.1666H168.624C168.858 67.1666 168.975 67.2838 168.975 67.5181V69.3506C168.975 69.585 168.858 69.7021 168.624 69.7021H167.822Z" fill="currentColor"/>
    </svg>
  )
}
