

const LogoWhite = () => {
  return (
    <svg width="221" height="48" viewBox="0 0 221 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0317 30.5293C27.4119 31.1878 26.9367 32.011 26.1762 32.011H11.695C10.9346 32.011 10.4594 31.1878 10.8396 30.5293L18.0802 17.9883C18.4604 17.3297 19.4109 17.3297 19.7911 17.9883L27.0317 30.5293Z" fill="white" />
      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M46.7256 30.5293C47.1058 31.1878 46.6305 32.011 45.8701 32.011H31.3889C30.6285 32.011 30.1532 31.1878 30.5334 30.5293L37.774 17.9883C38.1542 17.3297 39.1047 17.3297 39.485 17.9883L46.7256 30.5293Z" fill="white" />
      <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M46.7256 34.5266C47.1058 33.868 46.6305 33.0449 45.8701 33.0449H31.3889C30.6285 33.0449 30.1532 33.868 30.5334 34.5266L37.774 47.0676C38.1542 47.7261 39.1047 47.7261 39.485 47.0676L46.7256 34.5266Z" fill="white" />
      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9.94553 30.0117C9.56532 30.6703 8.6148 30.6703 8.23458 30.0117L0.993987 17.4707C0.613772 16.8122 1.08903 15.989 1.84946 15.989H16.3307C17.0911 15.989 17.5663 16.8122 17.1861 17.4707L9.94553 30.0117Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8795 13.4736C37.2597 14.1322 36.7844 14.9554 36.024 14.9554L21.5428 14.9554C20.7824 14.9554 20.3071 14.1322 20.6873 13.4736L27.9279 0.932628C28.3082 0.274085 29.2587 0.274085 29.6389 0.932628L36.8795 13.4736Z" fill="white" />
      <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M27.9264 30.0116C28.3066 30.6702 29.2572 30.6702 29.6374 30.0116L36.878 17.4706C37.2582 16.8121 36.7829 15.9889 36.0225 15.9889H21.5413C20.7809 15.9889 20.3056 16.8121 20.6858 17.4706L27.9264 30.0116Z" fill="white" />
      <path d="M65.475 20.5337C65.3955 19.8278 65.0674 19.2809 64.4907 18.8931C63.9139 18.5004 63.1881 18.304 62.3131 18.304C61.6867 18.304 61.1448 18.4034 60.6874 18.6023C60.23 18.7962 59.8745 19.0646 59.621 19.4077C59.3724 19.7457 59.2481 20.131 59.2481 20.5636C59.2481 20.9265 59.3326 21.2397 59.5017 21.5032C59.6757 21.7667 59.9019 21.9879 60.1803 22.1669C60.4637 22.3409 60.7669 22.4876 61.0901 22.6069C61.4132 22.7212 61.724 22.8157 62.0223 22.8903L63.5137 23.2781C64.0009 23.3974 64.5006 23.5589 65.0127 23.7628C65.5247 23.9666 65.9995 24.2351 66.437 24.5682C66.8745 24.9013 67.2275 25.3139 67.496 25.8061C67.7694 26.2983 67.9061 26.8874 67.9061 27.5735C67.9061 28.4386 67.6824 29.2067 67.235 29.8778C66.7925 30.549 66.1487 31.0785 65.3035 31.4663C64.4633 31.854 63.4466 32.0479 62.2534 32.0479C61.11 32.0479 60.1206 31.8665 59.2854 31.5036C58.4502 31.1406 57.7964 30.6261 57.3241 29.9599C56.8518 29.2887 56.5908 28.4933 56.5411 27.5735H58.8529C58.8976 28.1254 59.0766 28.5852 59.3898 28.9531C59.708 29.3161 60.1132 29.587 60.6054 29.766C61.1025 29.94 61.6469 30.027 62.2385 30.027C62.8898 30.027 63.469 29.9251 63.9761 29.7212C64.4882 29.5124 64.8909 29.2241 65.1842 28.8562C65.4775 28.4833 65.6242 28.0483 65.6242 27.5511C65.6242 27.0987 65.4949 26.7283 65.2364 26.44C64.9828 26.1516 64.6373 25.913 64.1998 25.7241C63.7673 25.5352 63.2776 25.3686 62.7307 25.2244L60.926 24.7322C59.703 24.3991 58.7335 23.9094 58.0176 23.2631C57.3067 22.6168 56.9512 21.7617 56.9512 20.6978C56.9512 19.8178 57.1899 19.0497 57.6671 18.3935C58.1444 17.7372 58.7907 17.2276 59.6061 16.8647C60.4214 16.4968 61.3411 16.3129 62.3653 16.3129C63.3994 16.3129 64.3117 16.4943 65.1022 16.8572C65.8976 17.2202 66.524 17.7198 66.9814 18.3562C67.4388 18.9876 67.6774 19.7134 67.6973 20.5337H65.475Z" fill="white" />
      <path d="M69.7458 31.7944V20.3398H71.9756V31.7944H69.7458ZM70.8719 18.5724C70.4841 18.5724 70.151 18.4432 69.8726 18.1847C69.5991 17.9212 69.4624 17.608 69.4624 17.245C69.4624 16.8771 69.5991 16.5639 69.8726 16.3054C70.151 16.0419 70.4841 15.9102 70.8719 15.9102C71.2596 15.9102 71.5903 16.0419 71.8637 16.3054C72.1421 16.5639 72.2813 16.8771 72.2813 17.245C72.2813 17.608 72.1421 17.9212 71.8637 18.1847C71.5903 18.4432 71.2596 18.5724 70.8719 18.5724Z" fill="white" />
      <path d="M76.47 16.5217V31.7944H74.2403V16.5217H76.47Z" fill="white" />
      <path d="M78.7348 31.7944V20.3398H80.9645V31.7944H78.7348ZM79.8608 18.5724C79.4731 18.5724 79.14 18.4432 78.8616 18.1847C78.5881 17.9212 78.4514 17.608 78.4514 17.245C78.4514 16.8771 78.5881 16.5639 78.8616 16.3054C79.14 16.0419 79.4731 15.9102 79.8608 15.9102C80.2486 15.9102 80.5792 16.0419 80.8527 16.3054C81.1311 16.5639 81.2703 16.8771 81.2703 17.245C81.2703 17.608 81.1311 17.9212 80.8527 18.1847C80.5792 18.4432 80.2486 18.5724 79.8608 18.5724Z" fill="white" />
      <path d="M88.0542 32.0256C86.9455 32.0256 85.991 31.7745 85.1906 31.2724C84.3951 30.7653 83.7836 30.0668 83.356 29.1768C82.9285 28.2869 82.7147 27.2678 82.7147 26.1193C82.7147 24.956 82.9335 23.9293 83.371 23.0394C83.8085 22.1445 84.4249 21.446 85.2204 20.9439C86.0158 20.4418 86.953 20.1907 88.0318 20.1907C88.9019 20.1907 89.6774 20.3523 90.3585 20.6754C91.0396 20.9936 91.589 21.4411 92.0066 22.0178C92.4292 22.5945 92.6803 23.2681 92.7598 24.0387H90.5897C90.4704 23.5018 90.197 23.0394 89.7694 22.6516C89.3468 22.2638 88.7801 22.07 88.0691 22.07C87.4477 22.07 86.9033 22.234 86.4359 22.5621C85.9736 22.8853 85.6132 23.3477 85.3546 23.9492C85.0961 24.5458 84.9668 25.2518 84.9668 26.0671C84.9668 26.9023 85.0936 27.6232 85.3472 28.2298C85.6007 28.8363 85.9587 29.3061 86.421 29.6392C86.8884 29.9723 87.4377 30.1389 88.0691 30.1389C88.4917 30.1389 88.8745 30.0618 89.2176 29.9077C89.5656 29.7486 89.8564 29.5224 90.0901 29.229C90.3287 28.9357 90.4953 28.5827 90.5897 28.1701H92.7598C92.6803 28.9109 92.4391 29.5721 92.0364 30.1538C91.6337 30.7354 91.0943 31.1928 90.4182 31.5259C89.747 31.859 88.959 32.0256 88.0542 32.0256Z" fill="white" />
      <path d="M99.2342 32.0256C98.1604 32.0256 97.2232 31.7795 96.4228 31.2873C95.6224 30.7951 95.0009 30.1065 94.5585 29.2216C94.116 28.3366 93.8948 27.3026 93.8948 26.1193C93.8948 24.9311 94.116 23.892 94.5585 23.0021C95.0009 22.1122 95.6224 21.4212 96.4228 20.929C97.2232 20.4368 98.1604 20.1907 99.2342 20.1907C100.308 20.1907 101.245 20.4368 102.046 20.929C102.846 21.4212 103.468 22.1122 103.91 23.0021C104.352 23.892 104.574 24.9311 104.574 26.1193C104.574 27.3026 104.352 28.3366 103.91 29.2216C103.468 30.1065 102.846 30.7951 102.046 31.2873C101.245 31.7795 100.308 32.0256 99.2342 32.0256ZM99.2417 30.1538C99.9377 30.1538 100.514 29.9698 100.972 29.6019C101.429 29.234 101.767 28.7443 101.986 28.1328C102.21 27.5213 102.322 26.8477 102.322 26.1119C102.322 25.381 102.21 24.7099 101.986 24.0984C101.767 23.4819 101.429 22.9872 100.972 22.6143C100.514 22.2415 99.9377 22.055 99.2417 22.055C98.5407 22.055 97.959 22.2415 97.4967 22.6143C97.0393 22.9872 96.6987 23.4819 96.475 24.0984C96.2563 24.7099 96.1469 25.381 96.1469 26.1119C96.1469 26.8477 96.2563 27.5213 96.475 28.1328C96.6987 28.7443 97.0393 29.234 97.4967 29.6019C97.959 29.9698 98.5407 30.1538 99.2417 30.1538Z" fill="white" />
      <path d="M108.557 24.9933V31.7944H106.328V20.3398H108.468V22.2042H108.61C108.873 21.5977 109.286 21.1104 109.848 20.7425C110.414 20.3746 111.128 20.1907 111.988 20.1907C112.768 20.1907 113.452 20.3548 114.039 20.6829C114.625 21.006 115.08 21.4883 115.403 22.1296C115.726 22.771 115.888 23.5639 115.888 24.5085V31.7944H113.658V24.777C113.658 23.9467 113.442 23.2979 113.009 22.8306C112.577 22.3583 111.983 22.1222 111.227 22.1222C110.71 22.1222 110.25 22.234 109.848 22.4577C109.45 22.6815 109.134 23.0096 108.9 23.4421C108.672 23.8697 108.557 24.3867 108.557 24.9933Z" fill="white" />
      <path d="M122.48 16.5217L126.731 28.9606H126.902L131.153 16.5217H133.644L128.147 31.7944H125.485L119.989 16.5217H122.48Z" fill="white" />
      <path d="M137.141 32.0479C136.415 32.0479 135.759 31.9137 135.172 31.6452C134.585 31.3718 134.12 30.9766 133.777 30.4595C133.439 29.9425 133.27 29.3086 133.27 28.5579C133.27 27.9116 133.395 27.3796 133.643 26.962C133.892 26.5444 134.227 26.2138 134.65 25.9702C135.072 25.7266 135.545 25.5426 136.067 25.4183C136.589 25.294 137.121 25.1996 137.663 25.1349C138.349 25.0554 138.906 24.9908 139.333 24.9411C139.761 24.8864 140.071 24.7994 140.265 24.68C140.459 24.5607 140.556 24.3668 140.556 24.0984V24.0462C140.556 23.3949 140.372 22.8903 140.004 22.5323C139.641 22.1744 139.099 21.9954 138.379 21.9954C137.628 21.9954 137.036 22.1619 136.604 22.495C136.176 22.8232 135.88 23.1886 135.716 23.5913L133.621 23.114C133.869 22.418 134.232 21.8562 134.71 21.4286C135.192 20.9961 135.746 20.6829 136.373 20.489C136.999 20.2901 137.658 20.1907 138.349 20.1907C138.806 20.1907 139.291 20.2454 139.803 20.3548C140.32 20.4592 140.802 20.6531 141.25 20.9364C141.702 21.2198 142.072 21.625 142.361 22.152C142.649 22.674 142.793 23.3526 142.793 24.1879V31.7944H140.616V30.2283H140.526C140.382 30.5167 140.166 30.8001 139.878 31.0785C139.589 31.3569 139.219 31.5881 138.766 31.772C138.314 31.956 137.772 32.0479 137.141 32.0479ZM137.625 30.2582C138.242 30.2582 138.769 30.1364 139.206 29.8928C139.649 29.6491 139.984 29.331 140.213 28.9382C140.447 28.5405 140.564 28.1154 140.564 27.663V26.1864C140.484 26.266 140.33 26.3406 140.101 26.4102C139.878 26.4748 139.621 26.532 139.333 26.5817C139.045 26.6264 138.764 26.6687 138.49 26.7085C138.217 26.7433 137.988 26.7731 137.804 26.7979C137.372 26.8526 136.977 26.9446 136.619 27.0739C136.266 27.2031 135.982 27.3896 135.768 27.6332C135.56 27.8718 135.455 28.19 135.455 28.5877C135.455 29.1396 135.659 29.5572 136.067 29.8406C136.474 30.119 136.994 30.2582 137.625 30.2582Z" fill="white" />
      <path d="M147.258 16.5217V31.7944H145.028V16.5217H147.258Z" fill="white" />
      <path d="M151.753 16.5217V31.7944H149.523V16.5217H151.753Z" fill="white" />
      <path d="M158.954 32.0256C157.825 32.0256 156.854 31.7844 156.038 31.3022C155.228 30.815 154.601 30.1314 154.159 29.2514C153.721 28.3665 153.503 27.3299 153.503 26.1417C153.503 24.9684 153.721 23.9343 154.159 23.0394C154.601 22.1445 155.218 21.446 156.008 20.9439C156.804 20.4418 157.734 20.1907 158.797 20.1907C159.444 20.1907 160.07 20.2976 160.677 20.5114C161.283 20.7251 161.828 21.0607 162.31 21.5181C162.792 21.9755 163.172 22.5696 163.451 23.3004C163.729 24.0263 163.868 24.9087 163.868 25.9478V26.7383H154.763V25.0678H161.683C161.683 24.4812 161.564 23.9616 161.325 23.5092C161.087 23.0518 160.751 22.6914 160.319 22.4279C159.891 22.1644 159.389 22.0327 158.812 22.0327C158.186 22.0327 157.639 22.1868 157.172 22.495C156.709 22.7983 156.351 23.196 156.098 23.6882C155.849 24.1754 155.725 24.7049 155.725 25.2766V26.5817C155.725 27.3473 155.859 27.9986 156.128 28.5355C156.401 29.0724 156.781 29.4826 157.269 29.766C157.756 30.0444 158.325 30.1836 158.976 30.1836C159.399 30.1836 159.784 30.1239 160.132 30.0046C160.48 29.8803 160.781 29.6964 161.035 29.4528C161.288 29.2092 161.482 28.9084 161.616 28.5504L163.727 28.9308C163.558 29.5522 163.254 30.0966 162.817 30.5639C162.384 31.0263 161.84 31.3867 161.184 31.6452C160.533 31.8988 159.789 32.0256 158.954 32.0256Z" fill="white" />
      <path d="M166.689 36.0898C166.356 36.0898 166.052 36.0625 165.779 36.0078C165.506 35.9581 165.302 35.9034 165.167 35.8438L165.704 34.0167C166.112 34.1261 166.475 34.1733 166.793 34.1584C167.111 34.1435 167.392 34.0241 167.636 33.8004C167.884 33.5767 168.103 33.2113 168.292 32.7042L168.568 31.9435L164.377 20.3398H166.763L169.664 29.229H169.784L172.684 20.3398H175.078L170.358 33.3232C170.139 33.9197 169.861 34.4244 169.523 34.837C169.184 35.2546 168.782 35.5678 168.314 35.7766C167.847 35.9854 167.305 36.0898 166.689 36.0898Z" fill="white" />
      <path d="M192.125 21.4883H189.799C189.709 20.9911 189.543 20.5536 189.299 20.1758C189.055 19.7979 188.757 19.4773 188.404 19.2138C188.051 18.9503 187.656 18.7514 187.218 18.6172C186.786 18.483 186.326 18.4158 185.839 18.4158C184.959 18.4158 184.171 18.6371 183.475 19.0795C182.784 19.522 182.237 20.1708 181.834 21.0259C181.437 21.881 181.238 22.9251 181.238 24.158C181.238 25.4009 181.437 26.4499 181.834 27.305C182.237 28.1602 182.786 28.8065 183.482 29.244C184.178 29.6815 184.961 29.9002 185.831 29.9002C186.314 29.9002 186.771 29.8356 187.204 29.7063C187.641 29.5721 188.036 29.3757 188.389 29.1172C188.742 28.8587 189.041 28.543 189.284 28.1701C189.533 27.7923 189.704 27.3597 189.799 26.8725L192.125 26.88C192.001 27.6307 191.76 28.3217 191.402 28.9531C191.049 29.5795 190.594 30.1214 190.037 30.5788C189.486 31.0313 188.854 31.3817 188.143 31.6303C187.432 31.8789 186.657 32.0032 185.816 32.0032C184.494 32.0032 183.316 31.69 182.282 31.0636C181.248 30.4322 180.432 29.5298 179.836 28.3565C179.244 27.1832 178.948 25.7837 178.948 24.158C178.948 22.5273 179.247 21.1278 179.843 19.9595C180.44 18.7862 181.255 17.8864 182.289 17.2599C183.323 16.6286 184.499 16.3129 185.816 16.3129C186.627 16.3129 187.383 16.4297 188.084 16.6634C188.79 16.892 189.423 17.2301 189.985 17.6776C190.547 18.12 191.012 18.6619 191.38 19.3033C191.748 19.9396 191.996 20.668 192.125 21.4883Z" fill="white" />
      <path d="M196.199 16.5217V31.7944H193.969V16.5217H196.199Z" fill="white" />
      <path d="M205.719 27.044V20.3398H207.957V31.7944H205.764V29.8107H205.645C205.381 30.4222 204.959 30.9318 204.377 31.3395C203.8 31.7422 203.082 31.9435 202.222 31.9435C201.486 31.9435 200.835 31.782 200.268 31.4588C199.706 31.1307 199.264 30.646 198.941 30.0046C198.622 29.3633 198.463 28.5703 198.463 27.6257V20.3398H200.693V27.3572C200.693 28.1378 200.909 28.7592 201.342 29.2216C201.774 29.6839 202.336 29.9151 203.027 29.9151C203.445 29.9151 203.86 29.8107 204.273 29.6019C204.69 29.3931 205.036 29.0774 205.309 28.6548C205.588 28.2322 205.724 27.6953 205.719 27.044Z" fill="white" />
      <path d="M210.397 31.7944V16.5217H212.626V22.1967H212.761C212.89 21.9581 213.076 21.6822 213.32 21.369C213.563 21.0558 213.902 20.7823 214.334 20.5487C214.767 20.31 215.338 20.1907 216.049 20.1907C216.974 20.1907 217.799 20.4244 218.525 20.8917C219.251 21.359 219.82 22.0327 220.233 22.9126C220.65 23.7926 220.859 24.8516 220.859 26.0895C220.859 27.3274 220.653 28.3889 220.24 29.2738C219.828 30.1538 219.261 30.8324 218.54 31.3097C217.819 31.782 216.996 32.0181 216.072 32.0181C215.376 32.0181 214.806 31.9013 214.364 31.6676C213.926 31.4339 213.583 31.1605 213.335 30.8473C213.086 30.5341 212.895 30.2557 212.761 30.0121H212.574V31.7944H210.397ZM212.582 26.0671C212.582 26.8725 212.698 27.5785 212.932 28.185C213.166 28.7915 213.504 29.2663 213.946 29.6094C214.389 29.9474 214.931 30.1165 215.572 30.1165C216.238 30.1165 216.795 29.94 217.242 29.587C217.69 29.229 218.028 28.7443 218.257 28.1328C218.49 27.5213 218.607 26.8327 218.607 26.0671C218.607 25.3114 218.493 24.6328 218.264 24.0312C218.04 23.4297 217.702 22.9549 217.25 22.6069C216.802 22.2589 216.243 22.0849 215.572 22.0849C214.926 22.0849 214.379 22.2514 213.931 22.5845C213.489 22.9176 213.153 23.3825 212.925 23.979C212.696 24.5756 212.582 25.2717 212.582 26.0671Z" fill="white" />
    </svg>
  )
}

export default LogoWhite;
