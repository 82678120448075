import React from 'react';
import styles from './index.module.css';

export const CookieSection = ({title, desc, children}) => {
  return (
    <div className={styles.container}>
      {Boolean(title) && <div className={styles.title}>{title}</div>}
      {Boolean(desc) && <div className={styles.description}>{desc}</div>}
      {Boolean(children) && children}
    </div>
  );
}
