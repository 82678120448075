export default () => {
  return (
    <svg width="240" height="120" viewBox="0 0 240 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72.4965 53.0581C72.6376 52.7289 72.8414 52.4859 73.1078 52.3292C73.3743 52.1568 73.6721 52.0705 74.0013 52.0705C74.4716 52.0705 74.8948 52.2273 75.271 52.5408C75.6472 52.8543 75.8353 53.2384 75.8353 53.6929C75.8353 53.9281 75.7805 54.1632 75.6708 54.3983L69.3222 67.8008C69.1498 68.1614 68.8912 68.4435 68.5463 68.6473C68.2014 68.8354 67.8252 68.9295 67.4177 68.9295C67.0101 68.9295 66.6339 68.8354 66.289 68.6473C65.9442 68.4435 65.6855 68.1614 65.5131 67.8008L59.1646 54.3983C59.0549 54.1789 59 53.9516 59 53.7165C59 53.2462 59.1959 52.8543 59.5878 52.5408C59.9797 52.2273 60.4186 52.0705 60.9046 52.0705C61.2337 52.0705 61.5316 52.1568 61.7981 52.3292C62.0802 52.4859 62.2918 52.7289 62.4329 53.0581L67.4647 64.0622L72.4965 53.0581Z" fill="currentColor" />
      <path d="M84.7705 69C83.1403 69 81.7138 68.6551 80.4911 67.9654C79.2684 67.2757 78.3279 66.2882 77.6695 65.0028C77.0112 63.7174 76.682 62.2125 76.682 60.4882C76.682 58.7639 77.0112 57.2669 77.6695 55.9972C78.3279 54.7118 79.2684 53.7243 80.4911 53.0346C81.7138 52.3449 83.1403 52 84.7705 52C86.824 52 88.5953 52.5643 90.0844 53.6929C90.3196 53.8811 90.4842 54.0692 90.5782 54.2573C90.6723 54.4454 90.7193 54.6805 90.7193 54.9627C90.7193 55.3702 90.6017 55.7229 90.3666 56.0207C90.1471 56.3029 89.8728 56.444 89.5436 56.444C89.3242 56.444 89.1282 56.4205 88.9558 56.3734C88.7991 56.3107 88.6188 56.2089 88.415 56.0678C87.788 55.6602 87.208 55.3702 86.675 55.1978C86.1421 55.0097 85.5699 54.9156 84.9586 54.9156C83.4538 54.9156 82.3173 55.3859 81.5492 56.3264C80.7968 57.2513 80.4206 58.6385 80.4206 60.4882C80.4206 64.219 81.9332 66.0844 84.9586 66.0844C85.5386 66.0844 86.0872 65.9982 86.6045 65.8257C87.1218 65.6376 87.7253 65.3398 88.415 64.9322C88.6501 64.7911 88.8461 64.6971 89.0028 64.6501C89.1596 64.5874 89.3399 64.556 89.5436 64.556C89.8728 64.556 90.1471 64.7049 90.3666 65.0028C90.6017 65.2849 90.7193 65.6298 90.7193 66.0373C90.7193 66.3195 90.6644 66.5625 90.5547 66.7663C90.4606 66.9544 90.3039 67.1346 90.0844 67.3071C88.5953 68.4357 86.824 69 84.7705 69Z" fill="currentColor" />
      <path d="M94.7283 68.9295C94.1483 68.9295 93.6702 68.7414 93.294 68.3651C92.9178 67.9733 92.7297 67.4873 92.7297 66.9073C92.7297 66.3273 92.9178 65.8492 93.294 65.473C93.6702 65.0811 94.1483 64.8852 94.7283 64.8852C95.3083 64.8852 95.7864 65.0811 96.1626 65.473C96.5388 65.8492 96.7269 66.3273 96.7269 66.9073C96.7269 67.4873 96.5388 67.9733 96.1626 68.3651C95.7864 68.7414 95.3083 68.9295 94.7283 68.9295Z" fill="currentColor" />
      <path d="M113.889 52.0941C114.437 52.0941 114.876 52.2586 115.205 52.5878C115.535 52.917 115.699 53.3559 115.699 53.9046V67.0954C115.699 67.6441 115.535 68.0908 115.205 68.4357C114.876 68.7649 114.437 68.9295 113.889 68.9295C113.34 68.9295 112.901 68.7649 112.572 68.4357C112.258 68.1065 112.102 67.6597 112.102 67.0954V61.8285H104.46V67.0954C104.46 67.6441 104.295 68.0908 103.966 68.4357C103.637 68.7649 103.206 68.9295 102.673 68.9295C102.124 68.9295 101.685 68.7649 101.356 68.4357C101.027 68.1065 100.862 67.6597 100.862 67.0954V53.9046C100.862 53.3559 101.027 52.917 101.356 52.5878C101.685 52.2586 102.124 52.0941 102.673 52.0941C103.222 52.0941 103.653 52.2586 103.966 52.5878C104.295 52.917 104.46 53.3559 104.46 53.9046V58.9129H112.102V53.9046C112.102 53.3559 112.258 52.917 112.572 52.5878C112.901 52.2586 113.34 52.0941 113.889 52.0941Z" fill="currentColor" />
      <path d="M126.621 69C124.975 69 123.54 68.6551 122.318 67.9654C121.111 67.26 120.178 66.2725 119.52 65.0028C118.861 63.7174 118.532 60.4882 118.532 60.4882C118.532 58.7639 118.861 57.2669 119.52 55.9972C120.178 54.7118 121.111 53.7243 122.318 53.0346C123.54 52.3449 124.975 52 126.621 52C128.267 52 129.693 52.3449 130.9 53.0346C132.123 53.7243 133.063 54.7118 133.722 55.9972C134.38 57.2669 134.709 58.7639 134.709 60.4882C134.709 62.2125 134.38 63.7174 133.722 65.0028C133.063 66.2725 132.123 67.26 130.9 67.9654C129.693 68.6551 128.267 69 126.621 69ZM126.621 66.1549C128 66.1549 129.082 65.669 129.865 64.6971C130.665 63.7252 131.065 62.3223 131.065 60.4882C131.065 58.6542 130.673 57.2591 129.889 56.3029C129.105 55.331 128.016 54.8451 126.621 54.8451C125.226 54.8451 124.136 55.331 123.352 56.3029C122.584 57.2591 122.2 58.6542 122.2 60.4882C122.2 62.3223 122.592 63.7252 123.376 64.6971C124.16 65.669 125.241 66.1549 126.621 66.1549Z" fill="currentColor" />
      <path d="M144.54 69C142.22 69 140.449 68.4043 139.226 67.213C138.019 66.006 137.416 64.2503 137.416 61.9461V53.9046C137.416 53.3559 137.58 52.917 137.909 52.5878C138.239 52.2586 138.678 52.0941 139.226 52.0941C139.775 52.0941 140.206 52.2586 140.519 52.5878C140.849 52.917 141.013 53.3559 141.013 53.9046V62.1107C141.013 63.4274 141.311 64.4306 141.907 65.1203C142.518 65.81 143.396 66.1549 144.54 66.1549C145.684 66.1549 146.562 65.81 147.174 65.1203C147.785 64.4149 148.091 63.4117 148.091 62.1107V53.9046C148.091 53.3559 148.247 52.917 148.561 52.5878C148.89 52.2586 149.329 52.0941 149.878 52.0941C150.426 52.0941 150.865 52.2586 151.194 52.5878C151.524 52.917 151.688 53.3559 151.688 53.9046V61.9461C151.688 64.2347 151.077 65.9825 149.854 67.1895C148.631 68.3965 146.86 69 144.54 69Z" fill="currentColor" />
      <path d="M160.864 69C159.626 69 158.474 68.8589 157.408 68.5768C156.342 68.2789 155.472 67.8635 154.798 67.3306C154.563 67.1581 154.39 66.9779 154.281 66.7898C154.187 66.586 154.14 66.3352 154.14 66.0373C154.14 65.6455 154.257 65.3006 154.492 65.0028C154.743 64.7049 155.025 64.556 155.339 64.556C155.511 64.556 155.676 64.5874 155.832 64.6501C156.005 64.6971 156.209 64.7911 156.444 64.9322C157.118 65.3555 157.8 65.669 158.489 65.8728C159.179 66.0609 159.939 66.1549 160.77 66.1549C161.805 66.1549 162.596 65.9825 163.145 65.6376C163.694 65.2928 163.968 64.799 163.968 64.1563C163.968 63.6704 163.709 63.2785 163.192 62.9806C162.69 62.6828 161.789 62.4006 160.488 62.1342C159.03 61.8363 157.862 61.4758 156.985 61.0526C156.122 60.6293 155.488 60.1042 155.08 59.4772C154.688 58.8502 154.492 58.0899 154.492 57.1964C154.492 56.2089 154.774 55.3232 155.339 54.5394C155.919 53.74 156.71 53.1208 157.714 52.6819C158.732 52.2273 159.877 52 161.146 52C163.372 52 165.206 52.5565 166.648 53.6694C166.884 53.8575 167.048 54.0535 167.142 54.2573C167.252 54.4454 167.307 54.6805 167.307 54.9627C167.307 55.3545 167.181 55.6994 166.931 55.9972C166.695 56.2951 166.421 56.444 166.108 56.444C165.935 56.444 165.771 56.4205 165.614 56.3734C165.473 56.3264 165.269 56.2245 165.003 56.0678C164.36 55.6602 163.764 55.3545 163.216 55.1508C162.683 54.947 162.001 54.8451 161.17 54.8451C160.214 54.8451 159.461 55.0332 158.913 55.4094C158.364 55.7699 158.09 56.2716 158.09 56.9142C158.09 57.2905 158.192 57.604 158.395 57.8548C158.615 58.0899 158.968 58.3015 159.453 58.4896C159.955 58.6777 160.66 58.8737 161.57 59.0775C163.701 59.5477 165.23 60.1512 166.155 60.888C167.095 61.6247 167.565 62.6358 167.565 63.9212C167.565 64.9244 167.283 65.8101 166.719 66.5781C166.17 67.3462 165.387 67.9419 164.368 68.3651C163.364 68.7884 162.197 69 160.864 69Z" fill="currentColor" />
      <path d="M171.924 68.7884C171.375 68.7884 170.952 68.6395 170.654 68.3416C170.356 68.0438 170.208 67.6206 170.208 67.0719V53.9281C170.208 53.3794 170.356 52.9562 170.654 52.6584C170.952 52.3605 171.375 52.2116 171.924 52.2116H180.248C181.408 52.2116 181.988 52.6819 181.988 53.6224C181.988 54.5786 181.408 55.0567 180.248 55.0567H173.782V58.9364H179.777C180.937 58.9364 181.517 59.4145 181.517 60.3707C181.517 61.3112 180.937 61.7815 179.777 61.7815H173.782V65.9433H180.248C181.408 65.9433 181.988 66.4214 181.988 67.3776C181.988 68.3181 181.408 68.7884 180.248 68.7884H171.924Z" fill="currentColor" />
    </svg>
  )
}
