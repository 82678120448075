import React, { useEffect, useState } from "react"

import styles from './index.module.css';
import { Link } from "react-router-dom";


const YEAR = 365 * 24 * 3600 * 1000 //in mileseconds;
const NOW = new Date().getTime();
const expires = NOW + YEAR;

const checkCookieExpires = (date) => {
  return NOW > date
}

const CookieWidget = () => {
  const [shouldShow, setShouldShow] = useState(true);

  const setCookie = () => {
    localStorage.setItem('cookieExpires', expires)
    setShouldShow(false)
  }
  
  useEffect(() => {
    const cookieExpires = localStorage.getItem('cookieExpires');
    if (cookieExpires) {
      const show = checkCookieExpires(cookieExpires)
      setShouldShow(show)
    }
  }, [])

  return shouldShow ? (
    <div className={styles.cookieWrapper}>
      <div className={styles.cookieText}>
        <span>By using this website you accept our</span>
        &nbsp;
        <Link to={"/cookie-policy"} className={styles.cookieLink}>
          cookie policy
        </Link>
      </div>
      <div onClick={setCookie} className={styles.cookieButton}>
        Accept
      </div>
    </div>
  ) : null;
}

export default CookieWidget;
