import React from "react";

const Attention = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z"
          fill="white"
        />
        <path d="M10 6.66675V10.0001" stroke="#3B33FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 13.3333H10.0083" stroke="#3B33FB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default Attention;
