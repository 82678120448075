

const Location = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5476 14.5922C19.4603 13.2925 19.9961 11.7088 19.9961 10C19.9961 5.58172 16.4144 2 11.9961 2C7.57782 2 3.99609 5.58172 3.99609 10C3.99609 11.7088 4.53187 13.2925 5.44459 14.5922C6.46488 16.0452 9.30273 18.9508 10.8882 20.5387C11.502 21.1535 12.4894 21.1535 13.1032 20.5388C14.6889 18.951 17.5273 16.0452 18.5476 14.5922ZM11.9996 7.5C10.6189 7.5 9.49957 8.61929 9.49957 10C9.49957 11.3807 10.6189 12.5 11.9996 12.5C13.3803 12.5 14.4996 11.3807 14.4996 10C14.4996 8.61929 13.3803 7.5 11.9996 7.5Z" fill="#8A8A88" />
    </svg>
  );
};

export default Location;
