

const LogoColored = () => {
  return (
    <svg width="221" height="49" viewBox="0 0 221 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0317 31.0389C27.4119 31.6974 26.9367 32.5206 26.1762 32.5206H11.695C10.9346 32.5206 10.4594 31.6974 10.8396 31.0389L18.0802 18.4979C18.4604 17.8393 19.4109 17.8393 19.7911 18.4979L27.0317 31.0389Z" fill="#252627" />
      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M46.7256 31.0389C47.1058 31.6974 46.6305 32.5206 45.8701 32.5206H31.3889C30.6285 32.5206 30.1532 31.6974 30.5334 31.0389L37.774 18.4979C38.1542 17.8393 39.1047 17.8393 39.485 18.4979L46.7256 31.0389Z" fill="#252627" />
      <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M46.7256 35.0362C47.1058 34.3776 46.6305 33.5544 45.8701 33.5544H31.3889C30.6285 33.5544 30.1532 34.3776 30.5334 35.0362L37.774 47.5772C38.1542 48.2357 39.1047 48.2357 39.485 47.5772L46.7256 35.0362Z" fill="#252627" />
      <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9.94553 30.5213C9.56532 31.1799 8.6148 31.1799 8.23458 30.5213L0.993987 17.9803C0.613772 17.3218 1.08903 16.4986 1.84946 16.4986H16.3307C17.0911 16.4986 17.5663 17.3218 17.1861 17.9803L9.94553 30.5213Z" fill="#252627" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8795 13.9832C37.2597 14.6418 36.7844 15.4649 36.024 15.4649L21.5428 15.4649C20.7824 15.4649 20.3071 14.6417 20.6873 13.9832L27.9279 1.44221C28.3082 0.783667 29.2587 0.783668 29.6389 1.44221L36.8795 13.9832Z" fill="#252627" />
      <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M27.9264 30.5212C28.3066 31.1797 29.2572 31.1797 29.6374 30.5212L36.878 17.9802C37.2582 17.3217 36.7829 16.4985 36.0225 16.4985H21.5413C20.7809 16.4985 20.3056 17.3217 20.6858 17.9802L27.9264 30.5212Z" fill="#252627" />
      <path d="M64.1965 21.2931C64.1269 20.6418 63.8335 20.1347 63.3165 19.7718C62.8044 19.4089 62.1382 19.2274 61.3179 19.2274C60.7412 19.2274 60.2465 19.3144 59.8339 19.4884C59.4212 19.6624 59.1056 19.8986 58.8868 20.1969C58.6681 20.4952 58.5562 20.8357 58.5512 21.2185C58.5512 21.5367 58.6233 21.8126 58.7675 22.0463C58.9166 22.28 59.118 22.4788 59.3715 22.6429C59.6251 22.802 59.906 22.9362 60.2142 23.0456C60.5225 23.155 60.8332 23.2469 61.1464 23.3215L62.5782 23.6795C63.1549 23.8137 63.7092 23.9952 64.2412 24.2239C64.7781 24.4526 65.2579 24.7409 65.6805 25.0889C66.108 25.4369 66.4461 25.857 66.6947 26.3492C66.9433 26.8414 67.0676 27.4181 67.0676 28.0793C67.0676 28.9742 66.8389 29.7622 66.3815 30.4433C65.9241 31.1195 65.2629 31.6489 64.3978 32.0317C63.5377 32.4096 62.4962 32.5985 61.2732 32.5985C60.085 32.5985 59.0534 32.4146 58.1784 32.0467C57.3083 31.6788 56.6272 31.1418 56.135 30.4359C55.6478 29.7299 55.3843 28.8698 55.3445 27.8556H58.0665C58.1063 28.3876 58.2703 28.83 58.5587 29.183C58.847 29.536 59.2224 29.7995 59.6847 29.9735C60.1521 30.1475 60.6741 30.2345 61.2508 30.2345C61.8524 30.2345 62.3793 30.145 62.8318 29.9661C63.2891 29.7821 63.6471 29.5286 63.9056 29.2054C64.1641 28.8773 64.2959 28.4945 64.3009 28.057C64.2959 27.6592 64.1791 27.3311 63.9504 27.0726C63.7217 26.8091 63.401 26.5903 62.9884 26.4163C62.5807 26.2374 62.1034 26.0783 61.5565 25.9391L59.819 25.4916C58.5612 25.1685 57.5668 24.6788 56.836 24.0225C56.1102 23.3613 55.7472 22.4838 55.7472 21.3901C55.7472 20.4902 55.9909 19.7022 56.4781 19.0261C56.9703 18.3499 57.6389 17.8254 58.4841 17.4526C59.3293 17.0747 60.2863 16.8858 61.3552 16.8858C62.439 16.8858 63.3886 17.0747 64.2039 17.4526C65.0242 17.8254 65.6681 18.345 66.1354 19.0112C66.6027 19.6724 66.8438 20.433 66.8587 21.2931H64.1965Z" fill="#252627" />
      <path d="M68.5748 32.3673V20.9128H71.2743V32.3673H68.5748ZM69.932 19.2871C69.5045 19.2871 69.1366 19.1454 68.8283 18.862C68.5201 18.5737 68.366 18.2281 68.366 17.8254C68.366 17.4178 68.5201 17.0722 68.8283 16.7889C69.1366 16.5005 69.5045 16.3563 69.932 16.3563C70.3645 16.3563 70.7324 16.5005 71.0357 16.7889C71.3439 17.0722 71.4981 17.4178 71.4981 17.8254C71.4981 18.2281 71.3439 18.5737 71.0357 18.862C70.7324 19.1454 70.3645 19.2871 69.932 19.2871Z" fill="#252627" />
      <path d="M75.9099 17.0946V32.3673H73.2104V17.0946H75.9099Z" fill="#252627" />
      <path d="M77.8459 32.3673V20.9128H80.5455V32.3673H77.8459ZM79.2032 19.2871C78.7756 19.2871 78.4077 19.1454 78.0995 18.862C77.7913 18.5737 77.6371 18.2281 77.6371 17.8254C77.6371 17.4178 77.7913 17.0722 78.0995 16.7889C78.4077 16.5005 78.7756 16.3563 79.2032 16.3563C79.6357 16.3563 80.0036 16.5005 80.3069 16.7889C80.6151 17.0722 80.7692 17.4178 80.7692 17.8254C80.7692 18.2281 80.6151 18.5737 80.3069 18.862C80.0036 19.1454 79.6357 19.2871 79.2032 19.2871Z" fill="#252627" />
      <path d="M87.478 32.5911C86.3345 32.5911 85.3526 32.34 84.5323 31.8379C83.717 31.3357 83.0881 30.6422 82.6456 29.7572C82.2081 28.8673 81.9893 27.8432 81.9893 26.6848C81.9893 25.5215 82.2131 24.4948 82.6605 23.6049C83.1079 22.71 83.7393 22.014 84.5547 21.5168C85.375 21.0147 86.3445 20.7636 87.4631 20.7636C88.3927 20.7636 89.2155 20.9352 89.9315 21.2782C90.6523 21.6163 91.2266 22.096 91.6541 22.7175C92.0817 23.334 92.3253 24.0548 92.3849 24.8801H89.8047C89.7003 24.3283 89.4517 23.8684 89.0589 23.5005C88.6712 23.1276 88.1516 22.9412 87.5003 22.9412C86.9485 22.9412 86.4638 23.0903 86.0462 23.3886C85.6285 23.682 85.3029 24.1045 85.0692 24.6564C84.8405 25.2082 84.7262 25.8695 84.7262 26.6401C84.7262 27.4206 84.8405 28.0918 85.0692 28.6536C85.2979 29.2104 85.6186 29.6404 86.0312 29.9437C86.4489 30.242 86.9386 30.3911 87.5003 30.3911C87.8981 30.3911 88.2535 30.3166 88.5668 30.1674C88.8849 30.0133 89.1509 29.792 89.3647 29.5037C89.5785 29.2153 89.7251 28.8648 89.8047 28.4522H92.3849C92.3203 29.2626 92.0817 29.981 91.669 30.6074C91.2564 31.2288 90.6946 31.7161 89.9837 32.069C89.2727 32.417 88.4375 32.5911 87.478 32.5911Z" fill="#252627" />
      <path d="M98.7581 32.5911C97.6395 32.5911 96.67 32.345 95.8497 31.8528C95.0294 31.3606 94.393 30.672 93.9406 29.7871C93.4932 28.9021 93.2695 27.868 93.2695 26.6848C93.2695 25.5016 93.4932 24.465 93.9406 23.5751C94.393 22.6852 95.0294 21.9941 95.8497 21.5019C96.67 21.0097 97.6395 20.7636 98.7581 20.7636C99.8767 20.7636 100.846 21.0097 101.666 21.5019C102.487 21.9941 103.121 22.6852 103.568 23.5751C104.021 24.465 104.247 25.5016 104.247 26.6848C104.247 27.868 104.021 28.9021 103.568 29.7871C103.121 30.672 102.487 31.3606 101.666 31.8528C100.846 32.345 99.8767 32.5911 98.7581 32.5911ZM98.773 30.4284C99.3795 30.4284 99.8866 30.2619 100.294 29.9288C100.702 29.5907 101.005 29.1383 101.204 28.5715C101.408 28.0048 101.51 27.3734 101.51 26.6773C101.51 25.9764 101.408 25.3425 101.204 24.7757C101.005 24.204 100.702 23.7491 100.294 23.411C99.8866 23.0729 99.3795 22.9039 98.773 22.9039C98.1516 22.9039 97.6345 23.0729 97.2219 23.411C96.8142 23.7491 96.5084 24.204 96.3046 24.7757C96.1057 25.3425 96.0063 25.9764 96.0063 26.6773C96.0063 27.3734 96.1057 28.0048 96.3046 28.5715C96.5084 29.1383 96.8142 29.5907 97.2219 29.9288C97.6345 30.2619 98.1516 30.4284 98.773 30.4284Z" fill="#252627" />
      <path d="M108.398 25.6557V32.3673H105.698V20.9128H108.278V22.8592H108.412C108.676 22.2178 109.096 21.7082 109.673 21.3304C110.254 20.9526 110.973 20.7636 111.828 20.7636C112.618 20.7636 113.307 20.9327 113.894 21.2707C114.485 21.6088 114.943 22.0985 115.266 22.7398C115.594 23.3812 115.756 24.1592 115.751 25.074V32.3673H113.051V25.4916C113.051 24.726 112.852 24.1269 112.454 23.6944C112.062 23.2619 111.517 23.0456 110.821 23.0456C110.349 23.0456 109.929 23.15 109.561 23.3588C109.198 23.5626 108.912 23.8585 108.703 24.2462C108.499 24.634 108.398 25.1038 108.398 25.6557Z" fill="#252627" />
      <path d="M122.187 17.0946L126.161 29.1159H126.318L130.285 17.0946H133.328L127.944 32.3673H124.528L119.151 17.0946H122.187Z" fill="#252627" />
      <path d="M136.487 32.5985C135.761 32.5985 135.107 32.4692 134.526 32.2107C133.949 31.9472 133.491 31.5594 133.153 31.0474C132.82 30.5353 132.654 29.9039 132.654 29.1532C132.654 28.5069 132.773 27.9724 133.012 27.5499C133.25 27.1273 133.576 26.7892 133.989 26.5357C134.401 26.2821 134.866 26.0907 135.383 25.9614C135.905 25.8272 136.445 25.7303 137.001 25.6706C137.673 25.601 138.217 25.5389 138.635 25.4842C139.052 25.4245 139.355 25.335 139.544 25.2157C139.738 25.0914 139.835 24.9 139.835 24.6415V24.5967C139.835 24.0349 139.669 23.5999 139.336 23.2917C139.002 22.9835 138.523 22.8293 137.896 22.8293C137.235 22.8293 136.711 22.9735 136.323 23.2619C135.94 23.5502 135.681 23.8908 135.547 24.2835L133.027 23.9256C133.226 23.2295 133.554 22.6479 134.011 22.1805C134.468 21.7082 135.028 21.3553 135.689 21.1216C136.35 20.883 137.081 20.7636 137.881 20.7636C138.433 20.7636 138.983 20.8283 139.529 20.9575C140.076 21.0868 140.576 21.3006 141.028 21.5989C141.481 21.8922 141.844 22.2924 142.117 22.7995C142.396 23.3066 142.535 23.9405 142.535 24.7011V32.3673H139.94V30.7938H139.85C139.686 31.112 139.455 31.4103 139.157 31.6887C138.863 31.9621 138.493 32.1834 138.045 32.3524C137.603 32.5165 137.083 32.5985 136.487 32.5985ZM137.188 30.6148C137.73 30.6148 138.2 30.508 138.597 30.2942C138.995 30.0754 139.301 29.7871 139.515 29.4291C139.733 29.0712 139.843 28.6809 139.843 28.2583V26.9085C139.758 26.9781 139.614 27.0428 139.41 27.1024C139.211 27.1621 138.988 27.2143 138.739 27.259C138.49 27.3038 138.244 27.3435 138.001 27.3783C137.757 27.4131 137.546 27.443 137.367 27.4678C136.964 27.5225 136.604 27.612 136.286 27.7363C135.967 27.8606 135.716 28.0346 135.532 28.2583C135.348 28.4771 135.256 28.7604 135.256 29.1085C135.256 29.6056 135.438 29.981 135.801 30.2345C136.164 30.4881 136.626 30.6148 137.188 30.6148Z" fill="#252627" />
      <path d="M147.109 17.0946V32.3673H144.409V17.0946H147.109Z" fill="#252627" />
      <path d="M151.744 17.0946V32.3673H149.045V17.0946H151.744Z" fill="#252627" />
      <path d="M158.751 32.5911C157.603 32.5911 156.611 32.3524 155.776 31.8751C154.946 31.3929 154.307 30.7118 153.859 29.8318C153.412 28.9469 153.188 27.9053 153.188 26.7072C153.188 25.5289 153.412 24.4948 153.859 23.6049C154.312 22.71 154.943 22.014 155.754 21.5168C156.564 21.0147 157.516 20.7636 158.61 20.7636C159.316 20.7636 159.982 20.878 160.608 21.1067C161.24 21.3304 161.797 21.6784 162.279 22.1507C162.766 22.623 163.149 23.2246 163.427 23.9554C163.706 24.6813 163.845 25.5463 163.845 26.5506V27.3783H154.456V25.5587H161.257C161.252 25.0417 161.14 24.5818 160.922 24.1791C160.703 23.7715 160.397 23.4508 160.004 23.2171C159.617 22.9835 159.164 22.8666 158.647 22.8666C158.095 22.8666 157.611 23.0009 157.193 23.2693C156.775 23.5328 156.45 23.8808 156.216 24.3134C155.987 24.7409 155.87 25.2107 155.865 25.7228V27.3112C155.865 27.9774 155.987 28.5492 156.231 29.0264C156.475 29.4987 156.815 29.8617 157.253 30.1152C157.69 30.3638 158.202 30.4881 158.789 30.4881C159.182 30.4881 159.537 30.4334 159.855 30.324C160.173 30.2097 160.449 30.0431 160.683 29.8244C160.917 29.6056 161.093 29.3347 161.212 29.0115L163.733 29.2949C163.574 29.9611 163.271 30.5428 162.823 31.0399C162.381 31.5321 161.814 31.9149 161.123 32.1884C160.432 32.4568 159.641 32.5911 158.751 32.5911Z" fill="#252627" />
      <path d="M166.639 36.6628C166.271 36.6628 165.93 36.633 165.617 36.5733C165.309 36.5186 165.063 36.454 164.879 36.3794L165.505 34.2764C165.898 34.3908 166.248 34.4455 166.556 34.4405C166.865 34.4355 167.136 34.3386 167.369 34.1496C167.608 33.9657 167.809 33.6575 167.973 33.2249L168.205 32.606L164.051 20.9128H166.914L169.554 29.5634H169.674L172.321 20.9128H175.192L170.606 33.7544C170.392 34.3609 170.109 34.8805 169.756 35.313C169.403 35.7505 168.97 36.0836 168.458 36.3123C167.951 36.546 167.344 36.6628 166.639 36.6628Z" fill="#252627" />
      <path d="M191.781 22.2477H188.992C188.913 21.7903 188.766 21.3851 188.552 21.0321C188.338 20.6741 188.072 20.3709 187.754 20.1223C187.436 19.8737 187.073 19.6873 186.666 19.563C186.263 19.4337 185.828 19.3691 185.36 19.3691C184.53 19.3691 183.794 19.5779 183.153 19.9955C182.512 20.4082 182.01 21.0147 181.647 21.8151C181.284 22.6106 181.102 23.5825 181.102 24.731C181.102 25.8993 181.284 26.8837 181.647 27.6841C182.015 28.4795 182.517 29.0811 183.153 29.4888C183.794 29.8915 184.528 30.0928 185.353 30.0928C185.81 30.0928 186.238 30.0332 186.636 29.9139C187.038 29.7896 187.399 29.6081 187.717 29.3695C188.04 29.1308 188.311 28.8375 188.53 28.4895C188.754 28.1415 188.908 27.7438 188.992 27.2963L191.781 27.3112C191.677 28.0371 191.451 28.7182 191.103 29.3545C190.76 29.9909 190.31 30.5527 189.753 31.0399C189.196 31.5222 188.545 31.9 187.799 32.1734C187.053 32.4419 186.226 32.5761 185.316 32.5761C183.973 32.5761 182.775 32.2654 181.721 31.644C180.667 31.0225 179.837 30.1251 179.231 28.9518C178.624 27.7786 178.321 26.3716 178.321 24.731C178.321 23.0854 178.626 21.6784 179.238 20.5101C179.849 19.3368 180.682 18.4394 181.736 17.818C182.79 17.1965 183.983 16.8858 185.316 16.8858C186.166 16.8858 186.956 17.0051 187.687 17.2438C188.418 17.4824 189.069 17.8329 189.641 18.2952C190.213 18.7526 190.683 19.3144 191.05 19.9806C191.423 20.6418 191.667 21.3975 191.781 22.2477Z" fill="#252627" />
      <path d="M196.055 17.0946V32.3673H193.356V17.0946H196.055Z" fill="#252627" />
      <path d="M205.292 27.5499V20.9128H207.992V32.3673H205.374V30.3315H205.255C204.996 30.9728 204.571 31.4973 203.979 31.905C203.393 32.3126 202.669 32.5165 201.809 32.5165C201.059 32.5165 200.395 32.3499 199.818 32.0168C199.247 31.6788 198.799 31.1891 198.476 30.5477C198.153 29.9014 197.991 29.1209 197.991 28.2061V20.9128H200.691V27.7885C200.691 28.5143 200.89 29.0911 201.287 29.5186C201.685 29.9462 202.207 30.1599 202.853 30.1599C203.251 30.1599 203.636 30.063 204.009 29.8691C204.382 29.6752 204.688 29.3869 204.927 29.0041C205.17 28.6163 205.292 28.1315 205.292 27.5499Z" fill="#252627" />
      <path d="M210.047 32.3673V17.0946H212.746V22.807H212.858C212.998 22.5286 213.194 22.2327 213.447 21.9195C213.701 21.6014 214.044 21.3304 214.477 21.1067C214.909 20.878 215.461 20.7636 216.132 20.7636C217.017 20.7636 217.815 20.9898 218.526 21.4423C219.242 21.8897 219.809 22.5534 220.226 23.4334C220.649 24.3084 220.86 25.3822 220.86 26.655C220.86 27.9128 220.654 28.9817 220.241 29.8617C219.829 30.7416 219.267 31.4128 218.556 31.8751C217.845 32.3375 217.039 32.5687 216.14 32.5687C215.483 32.5687 214.939 32.4593 214.506 32.2406C214.074 32.0218 213.726 31.7583 213.462 31.4501C213.204 31.1369 213.003 30.8411 212.858 30.5626H212.702V32.3673H210.047ZM212.694 26.6401C212.694 27.3808 212.799 28.0296 213.007 28.5864C213.221 29.1433 213.527 29.5783 213.925 29.8915C214.327 30.1997 214.815 30.3538 215.386 30.3538C215.983 30.3538 216.483 30.1947 216.885 29.8766C217.288 29.5534 217.591 29.1134 217.795 28.5566C218.004 27.9948 218.108 27.356 218.108 26.6401C218.108 25.9291 218.006 25.2977 217.803 24.7459C217.599 24.194 217.295 23.7615 216.893 23.4483C216.49 23.1351 215.988 22.9785 215.386 22.9785C214.81 22.9785 214.32 23.1301 213.917 23.4334C213.515 23.7367 213.209 24.1617 213 24.7086C212.796 25.2555 212.694 25.8993 212.694 26.6401Z" fill="#252627" />
    </svg>

  )
}

export default LogoColored;
