import {BrowserRouter} from 'react-router-dom';
import AppRouter from './components/AppRouter';
import { ErrorBoundary } from './providers/ErrorBoundary/ErrorBoundary';
import CookieWidget from './components/CookieWidget';

const App = ()=> {
  console.log();
  return (
    <BrowserRouter>
     <ErrorBoundary>
         <AppRouter/>
          <CookieWidget />
     </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
